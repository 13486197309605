import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import { useSpring, animated } from '@react-spring/web';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import PhoneInput from 'react-phone-input-2';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import AppleLogin from 'react-apple-login';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import {
  Background,
  ModalContent,
  ModalWrapper,
  CloseModalButton,
  Title,
  RegisterForm,
  CityState,
  NumberNeighborhood,
  DataAccess,
  AuxTextFooter,
  HeaderBar,
  PasswordBox,
  InputMasked,
} from './styles';
import api, { LOGIN_API_URL } from '../../services/api';
import Loader from '../Loader';
import { ICountry, IProps, IState } from './contracts';
import viaCep from '../../services/viaCep';
import { errorMessages, errorMessageTypes } from '../../constants/messages';
import { googleLogin, signInRequest, updateUserProfileRequest } from '../../store/ducks/auth/actions';
import { SignUpConfirmModal } from '../SignUpConfirmModal';
import { EmailAlreadyExistsModal } from '../EmailAlreadyExistsModal';
import { IStore } from '../../contracts';
import theme from '../../theme';
import SocialLoginButton from '../SocialLoginButton';

const ZipcodeInput = (props: any) => (
  <InputMasked
    mask="99999-999"
    defaultValue={props.defaultValue}
    onChange={props.onChange}
  />
);

const CPFInput = (props: any) => (
  <InputMasked
    mask="999.999.999-99"
    defaultValue={props.defaultValue}
    onChange={props.onChange}
  />
);

export const SignUpModal = ({
  showModal, setShowModal, setPaymentProfile, productName, gratuity,
}: IProps) => {
  const dispatch = useDispatch();
  const modalRef = useRef<HTMLDivElement>(null);

  const animation = useSpring({
    to: {
      config: {
        duration: 250,
      },
      opacity: showModal ? 1 : 0,
      transform: showModal ? 'translateY(0%)' : 'translateY(-100%)',
    },
  });

  const closeModal = (e: { target: any; }) => {
    if (modalRef.current === e.target) {
      setShowModal(false);
    }
  };

  const keyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape' && showModal) {
        setShowModal(false);
      }
    },
    [setShowModal, showModal],
  );

  useEffect(
    () => {
      document.addEventListener('keydown', keyPress);
      return () => document.removeEventListener('keydown', keyPress);
    },
    [keyPress],
  );

  // fields
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [address, setAddress] = useState('');
  const [number, setNumber] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [complement, setComplement] = useState('');
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [states, setStates] = useState<IState[]>([]);
  const [countries, setCountries] = useState<ICountry[]>([]);

  // field errors
  const [nameError, setNameError] = useState<string | null>(null);
  const [phoneError, setPhoneError] = useState<string | null>(null);
  const [countryError, setCountryError] = useState<string | null>(null);
  const [zipcodeError, setZipcodeError] = useState<string | null>(null);
  const [cityError, setCityError] = useState<string | null>(null);
  const [stateError, setStateError] = useState<string | null>(null);
  const [addressError, setAddressError] = useState<string | null>(null);
  const [numberError, setNumberError] = useState<string | null>(null);
  const [neighborhoodError, setNeighborhoodError] = useState<string | null>(null);
  const [cpfError, setCpfError] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalEmailExists, setModalEmailExists] = useState(false);

  const userId = useSelector((state: IStore) => state.auth.profile?.id);

  useEffect(() => {
    api.get('countries')
      .then((response) => {
        setCountries(response.data);
        setCountry('Brasil');
      });
  }, []);

  useEffect(() => {
    api.get('states')
      .then((response) => {
        setStates(response.data?.estados);
        setState('AC');
        setCity('Acrelândia');
      });
  }, []);

  function validateData() {
    if (!gratuity) {
      if (!name) setNameError('*Campo obrigatório');
      if (!phone) setPhoneError('*Campo obrigatório');
      if (!country) setCountryError('*Campo obrigatório');
      if (!zipcode) setZipcodeError('*Campo obrigatório');
      if (!city) setCityError('*Campo obrigatório');
      if (!state) setStateError('*Campo obrigatório');
      if (!address) setAddressError('*Campo obrigatório');
      if (!number) setNumberError('*Campo obrigatório');
      if (!neighborhood) setNeighborhoodError('*Campo obrigatório');
      if (country === 'Brasil' && !cpf) setCpfError('*Campo obrigatório');
      if (!email) setEmailError('*Campo obrigatório');
      if (!password) setPasswordError('*Campo obrigatório');

      if (
        !name
        || !phone
        || !country
        || !zipcode
        || !city
        || !state
        || !address
        || !number
        || !neighborhood
        || (country === 'Brasil' && !cpf)
        || !email
        || !password
      ) {
        return false;
      }
    } else {
      if (!name) setNameError('*Campo obrigatório');
      if (!phone) setPhoneError('*Campo obrigatório');
      if (!email) setEmailError('*Campo obrigatório');
      if (!password) setPasswordError('*Campo obrigatório');

      if (
        !name
        || !phone
        || !email
        || !password
      ) {
        return false;
      }
    }

    return true;
  }

  async function handleSubmit(event: { preventDefault: () => void; }, sendDataConfirmed = false) {
    event.preventDefault();

    const successValidate = validateData();

    if (!successValidate) return;

    if (!sendDataConfirmed) {
      setModalConfirm(true);
      return;
    }

    setLoading(true);

    try {
      const data = {
        name,
        phone,
        country,
        zipcode: zipcode.replaceAll('-', '').replaceAll('.', '').replaceAll('/', ''),
        city,
        state,
        street: address,
        number,
        district: neighborhood,
        complement,
        cpf: cpf.replaceAll('.', '').replaceAll('-', ''),
        email,
        password,
        product_name: productName,
      };

      await api.post('/storeFromCheckout', data);

      setLoading(false);

      setPaymentProfile(null);

      setShowModal(false);

      dispatch(signInRequest(email, password));
    } catch (error: any) {
      setLoading(false);

      if (error.response.data?.message === errorMessageTypes.USER_ALREADY_EXISTS) {
        toast.error(errorMessages.USER_ALREADY_EXISTS);
        setModalEmailExists(true);
      }
    }
  }

  function handleSetZipcode(value: string) {
    const zipcodeReplaced = value.replaceAll('-', '').replaceAll('.', '').replaceAll('/', '').replaceAll('_', '');

    if (zipcodeReplaced.length === 8) {
      setLoading(true);

      viaCep.get(`${zipcodeReplaced}/json`)
        .then((response) => {
          if (response.data?.erro === true) {
            toast.error(errorMessages.ZIPCODE_NOT_FOUND);
          }

          if (response.data.logradouro) setAddress(response.data.logradouro);
          if (response.data.complemento) setComplement(response.data.complemento);
          if (response.data.bairro) setNeighborhood(response.data.bairro);
          if (response.data.localidade) setCity(response.data.localidade);
          if (response.data.uf) {
            const selectedState = states.find((st) => st.sigla === response.data.uf);

            setState(selectedState?.sigla!);
          }

          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }

    setZipcode(value);
  }

  function handleConfirmSubmit(event: { preventDefault: () => void; }) {
    handleSubmit(event, true);
  }

  function setShowModalCallback(value: boolean) {
    setModalConfirm(value);
    setShowModal(value);
  }

  function updateUserProfileCallback() {
    const profilePayload = {
      name,
      phone,
      cpf: cpf.replaceAll('.', '').replaceAll('-', ''),
    };

    const addressPayload = {
      country,
      zipcode: zipcode.replaceAll('-', '').replaceAll('.', '').replaceAll('/', '').replaceAll('_', ''),
      city,
      state,
      street: address,
      number,
      district: neighborhood,
      complement,
    };

    dispatch(updateUserProfileRequest(userId, profilePayload, addressPayload));
  }

  const handleSetState = (value: string) => {
    setState(value);
    setCity(states.find((item) => item.sigla === value)?.cidades[0]!);
  };

  const googleLoginHandle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userInfo = await axios
        .get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        })
        .then((res) => res.data);

      const data = {
        name: userInfo.name ?? `${userInfo.givenName} ${userInfo.familyName}`,
        email: userInfo.email,
        token: tokenResponse.access_token,
        photo: userInfo.picture ?? '',
        provider: 'google',
        origin: 'WEB',
      };

      dispatch(googleLogin(data));
    },
  });

  return (
    <>
      {loading && <Loader />}

      {showModal ? (
        <>
          {
            !modalEmailExists
            && (
              <SignUpConfirmModal
                showModal={modalConfirm}
                setShowModal={() => setModalConfirm(false)}
                finishPayment={(e) => handleConfirmSubmit(e)}
                email={email}
                cpf={cpf}
              />
            )
          }

          <EmailAlreadyExistsModal
            showModal={modalEmailExists}
            setShowModal={() => setModalEmailExists(false)}
            emailExists={email}
            setShowModalCallback={(value) => setShowModalCallback(value)}
            updateUserProfileCallback={() => updateUserProfileCallback()}
          />

          {
            !modalConfirm
            && !modalEmailExists
            && (
              <Background ref={modalRef}>
                <animated.div style={animation}>
                  <ModalWrapper showModal={showModal}>
                    <ModalContent>
                      <HeaderBar>
                        <Title>
                          <strong>Cadastro</strong>
                        </Title>

                        <CloseModalButton
                          aria-label="Close modal"
                          onClick={() => setShowModal((prev: boolean) => !prev)}
                        />
                      </HeaderBar>

                      <RegisterForm onSubmit={(e) => handleSubmit(e)}>
                        <label htmlFor="name">Nome completo</label>
                        <input type="text" id="name" name="name" value={name} onChange={(e) => setName(e.target.value)} />
                        {nameError && <small style={{ color: 'red', marginBottom: '5px' }}>{nameError}</small>}

                        <label htmlFor="phone">Telefone celular</label>
                        <PhoneInput
                          country="br"
                          value={phone}
                          onChange={(phone) => setPhone(phone)}
                          countryCodeEditable={false}
                          inputProps={{
                            name: 'phone',
                            id: 'phone',
                            required: true,
                          }}
                          containerStyle={{
                            width: '100%',
                            height: '47px',
                            border: 'none',
                            marginTop: '10px',
                            marginBottom: '10px',
                          }}
                          buttonStyle={{
                            border: 'none',
                            backgroundColor: theme.colors.cardB3,
                          }}
                          inputStyle={{
                            width: '100%',
                            height: '100%',
                            border: 'none',
                            backgroundColor: theme.colors.cardB3,
                          }}
                        />
                        {phoneError && <small style={{ color: 'red', marginBottom: '5px' }}>{phoneError}</small>}

                        <label htmlFor="country">País</label>
                        <select id="country" value={country} onChange={(e) => setCountry(e.target.value)}>
                          {
                            countries.map((country) => (
                              <option key={country.nome_pais} value={country.nome_pais}>{country.nome_pais}</option>
                            ))
                          }
                        </select>
                        {countryError && <small style={{ color: 'red', marginBottom: '5px' }}>{countryError}</small>}

                        <label htmlFor="zipcode">CEP</label>
                        {
                          country === 'Brasil'
                            ? (
                              <ZipcodeInput
                                defaultValue={zipcode}
                                onChange={(e: any) => handleSetZipcode(e.target.value)}
                                name="zipcode"
                                id="zipcode"
                              />
                            )
                            : <input type="text" id="zipcode" name="zipcode" value={zipcode} onChange={(e) => setZipcode(e.target.value)} />
                        }
                        {zipcodeError && <small style={{ color: 'red', marginBottom: '5px' }}>{zipcodeError}</small>}

                        <CityState>
                          <div id="city">
                            <label htmlFor="city">Cidade</label>
                            {
                              country === 'Brasil'
                                ? (
                                  <select id="city" name="city" value={city} onChange={(e) => setCity(e.target.value)}>
                                    {
                                      states.find((item) => item.sigla === state)?.cidades?.map((city) => (
                                        <option key={city} value={city}>{city}</option>
                                      ))
                                    }
                                  </select>
                                )
                                : <input type="text" name="city" id="city" value={city} onChange={(e) => setCity(e.target.value)} />
                            }
                            {cityError && <small style={{ color: 'red', marginBottom: '5px' }}>{cityError}</small>}
                          </div>

                          <div id="state">
                            <label htmlFor="state">Estado</label>
                            {
                              country === 'Brasil'
                                ? (
                                  <select id="state" name="state" value={state} onChange={(e) => handleSetState(e.target.value)}>
                                    {
                                      states.map((state) => (
                                        <option key={state.sigla} value={state.sigla}>{state.nome}</option>
                                      ))
                                    }
                                  </select>
                                )
                                : <input type="text" name="state" id="state" value={state} onChange={(e) => setState(e.target.value)} />
                            }
                            {stateError && <small style={{ color: 'red', marginBottom: '5px' }}>{stateError}</small>}
                          </div>
                        </CityState>

                        <label htmlFor="address">Endereço</label>
                        <input type="text" id="address" name="address" value={address} onChange={(e) => setAddress(e.target.value)} />
                        {addressError && <small style={{ color: 'red', marginBottom: '5px' }}>{addressError}</small>}

                        <NumberNeighborhood>
                          <div id="number">
                            <label htmlFor="number">Número</label>
                            <input type="text" id="number" name="number" value={number} onChange={(e) => setNumber(e.target.value)} />
                            {numberError && <small style={{ color: 'red', marginBottom: '5px' }}>{numberError}</small>}
                          </div>

                          <div id="neighborhood">
                            <label htmlFor="neighborhood">Bairro</label>
                            <input type="text" id="neighborhood" name="neighborhood" value={neighborhood} onChange={(e) => setNeighborhood(e.target.value)} />
                            {neighborhoodError && <small style={{ color: 'red', marginBottom: '5px' }}>{neighborhoodError}</small>}
                          </div>
                        </NumberNeighborhood>

                        <label htmlFor="complement">Complemento (Opcional)</label>
                        <input type="text" id="complement" name="complement" value={complement} onChange={(e) => setComplement(e.target.value)} />

                        {
                          country === 'Brasil'
                          && (
                            <>
                              <label htmlFor="cpf">CPF</label>
                              <CPFInput
                                defaultValue={cpf}
                                onChange={(e: any) => setCpf(e.target.value)}
                                name="cpf"
                                id="cpf"
                              />
                              {cpfError && <small style={{ color: 'red', marginBottom: '5px' }}>{cpfError}</small>}
                            </>
                          )
                        }

                        <DataAccess>
                          <div />
                          <p>Crie seu acesso</p>
                          <div />
                        </DataAccess>

                        <label htmlFor="email">E-mail para login</label>
                        <input type="email" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        {emailError && <small style={{ color: 'red', marginBottom: '5px' }}>{emailError}</small>}

                        <label htmlFor="password">Escolha uma senha</label>
                        <PasswordBox>
                          <input type={showPassword ? 'text' : 'password'} id="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                          <div onClick={() => setShowPassword(!showPassword)}>
                            {showPassword
                              ? <FaEyeSlash size={25} color="#fff" />
                              : <FaEye size={25} color="#fff" />}
                          </div>
                        </PasswordBox>
                        {passwordError && <small style={{ color: 'red', marginBottom: '5px' }}>{passwordError}</small>}

                        <AuxTextFooter>
                          <p>
                            Ao se cadastrar você concorda com os
                            {' '}

                            <strong>
                              <a href={process.env.REACT_APP_CP_TERMS_URL} target="_blank" rel="noopener noreferrer">termos de uso</a>
                            </strong>

                            {' '}
                            e a
                            {' '}

                            <strong><a href={process.env.REACT_APP_CP_POLICY_URL} target="_blank" rel="noopener noreferrer">política de privacidade</a></strong>
                            .
                          </p>
                        </AuxTextFooter>

                        <button type="submit">
                          <strong>Efetuar cadastro</strong>
                        </button>

                        <SocialLoginButton
                          title="Fazer login com Google"
                          loading={false}
                          type="google"
                          onClick={() => googleLoginHandle()}
                          disabled={false}
                        />

                        <AppleLogin
                          clientId="com.corridaperfeita.web"
                          redirectURI={`${LOGIN_API_URL}/apple-web-pay`}
                          callback={() => { }}
                          responseType="code id_token"
                          responseMode="form_post"
                          scope="name email"
                          render={(renderProps) => (
                            <SocialLoginButton
                              title="Fazer login com Apple"
                              loading={false}
                              type="apple"
                              onClick={renderProps.onClick}
                              disabled={false}
                            />
                          )}
                        />
                      </RegisterForm>
                    </ModalContent>
                  </ModalWrapper>
                </animated.div>
              </Background>
            )
          }
        </>
      ) : null}
    </>
  );
};
