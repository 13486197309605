import styled from 'styled-components';
import theme from '../../theme';

export const ProductName = styled.p`
  color: #fff;
  font-size: 12pt;
`;

export const AuthButtons = styled.div`
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  color: #fff;
  text-align: center;

  @media (max-width: 667px) {
    max-width: 350px;
  }
`;

export const AuxTextFooter = styled.div`
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  color: #fff;

  text-align: center;

  a {
    color: #fff;
  }

  @media (max-width: 667px) {
    max-width: 350px;
  }
`;

export const Steps = styled.div`
    background: ${theme.colors.cardB2};
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
    padding: 5px;

    @media (max-width: 667px) {
      font-size: 10pt;
    }
`;

export const Container = styled.section`
  background: ${theme.colors.cardB2};
  margin-top: 10px;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  text-align: center;
  border: none;

  @media (max-width: 667px) {
    max-width: 350px;
  }
`;

export const ContainerText = styled.p`
  text-align: start;
  color: #fff;
  margin-top: 20px;
`;

export const SignInButton = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 45px;
    border-radius: 10px;
    font-size: 14px;
    margin-top: 20px;
    background: #fff;
    color: ${theme.colors.primary};
    border: none;
    text-decoration: none;
    font-weight: bold;
    transition: 0.3s;

    &:hover {
      box-shadow: 0 5px 16px rgba(0, 0, 0, 0.5);
    }
`;

export const StoreBadges = styled.div`
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;

  @media (max-width: 667px) {
    max-width: 350px;
  }

  p {
    color: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
`;

export const BadgeButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 667px) {
    flex-direction: column;
  }
`;

export const EmbedQRCode = styled.embed`
  width: 200px;
  height: 200px;
  margin-top: 30px;
  background-color: #fff;
`;

export const CopyButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    height: 45px;
    border-radius: 10px;
    font-size: 14px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    background: #fff;
    color: ${theme.colors.primary};
    border: none;
    font-weight: bold;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      box-shadow: 0 5px 16px rgba(0, 0, 0, 0.5);
    }
`;
